<template>
  <div class="wrapper">
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="基本信息" name="first">
        <base-info />
      </el-tab-pane>
      <el-tab-pane label="用餐记录" name="second">
        <meal-record v-permission="'ManManageDetailSecondTab'" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import baseInfo from './baseInfo.vue'
import mealRecord from './mealRecord.vue'

export default {
  name: 'Detail',
  components: { baseInfo, mealRecord },
  data() {
    return {
      activeName: 'first'
    }
  },
  computed: {
  },
  created() {

  },
  methods: {
    handleClick() {}
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 10px;
  overflow: auto;
  .m-content{
    .mc-item{
      line-height: 30px;
      span{
        display: inline-block;
        width: 150px;
        text-align: right;
      }
    }
  }
  h4 {
    height: 40px;
    line-height: 40px;
    color: #333;
    padding-left: 20px;
    margin-bottom: 20px;
  }
}
</style>
